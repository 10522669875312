import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Heading, Subhead } from 'rebass'
import Button from '../../components/Elements/Button'
import Layout from '../../components/layout'

class Elements extends Component {
  render() {
    return (
      <Layout>
        <Heading>Awesome Title </Heading>
        <Subhead> Subhead </Subhead>
        <Button>
          <span>some text</span>
        </Button>
      </Layout>
    )
  }
}

Elements.propTypes = {}

export default Elements
